import React from 'react'
import styled from 'styled-components'
import presets from '../utils/presets'

const LogoColour = presets.black
const LogoLightColor = presets.faded

const Logo = styled.span`
  display: inline-block;
  ${'' /* width: ${rhythm(2)}; */}
  line-height:1;
  svg {
    position: relative;
    display: inline-block;
    margin: 0;
    width: 100%;
    ${'' /* max-width: ${rhythm(2)}; */}
    height: auto;
    fill: ${props => (props.faded ? LogoLightColor : LogoColour)};
  }
`

export default props => {
  return (
    <Logo {...props}>
      {props.children}
      <svg
        width="1024px"
        height="1024px"
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMin"
      >
        <path
          fill={LogoColour}
          d="M460.932,859.045c-41.439-57.747-105.4-111.604-120.158-131.027c-14.763-19.424-25.218-45.46-31.848-66.291 c-7.241-22.787,7.517-36.766,7.517-45.575c0-4.174-49.728-56.704-49.728-56.704s-16.568-59.308-16.049-107.73 c0.514-48.43,5.858-64.478,5.858-64.478H0c0,0,0,113.415,88.558,236.165c0.531,0.744,1.041,1.442,1.555,2.151v6.589 c0,0,48.378,74.699,55.35,76.713c6.28,1.806,30.103-7.196,53.404,0c23.308,7.183,60.599,34.374,78.074,52.827 c17.485,18.439,68.75,100.734,106.821,153.802c38.065,53.089,59.296,75.111,75.602,88.048 c16.324,12.949,27.206,20.466,31.859,20.466c4.336-0.012,12.343-4.255,16.06-6.486c7.289-4.37,24.333-15.786,26.151-21.747 c1.854-6.098-7.766-30.026-22.26-57.745C496.657,910.315,490.663,900.501,460.932,859.045z"
        />

        <path
          d="M512,0H256H0v256v100h256V256h256c141.385,0,256,114.615,256,256c0,141.385-114.615,256-256,256h-90.02 c21.302,21.824,44.299,47.041,63.33,73.562c30.967,43.178,37.584,54.19,52.449,82.559c7.791,14.9,14.23,28.723,18.622,39.974 c7.298,18.696,8.911,30.023,5.747,40.426c-1.928,6.319-5.561,12.04-11.614,18.049C815.294,1002.876,1024,781.813,1024,511.999 C1024,229.23,794.77,0,512,0z"
        />

        <path
          d="M359.386,932.974c-16.147-22.508-34.173-49.502-51.606-75.606c-20.701-31-44.164-66.135-52.621-75.055           		c-4.303-4.543-9.75-9.453-15.724-14.313H0v256h436.999C415.449,1005.981,391.933,978.365,359.386,932.974z"
        />
      </svg>
    </Logo>
  )
}
