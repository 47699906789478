import React from 'react'
import styled from 'styled-components'
import presets from '../utils/presets'
import {rhythm} from '../utils/typography'

const Diagonal = styled.div`
  position: relative;
  background-color: ${presets.brand};
  background-image: linear-gradient( ${presets.brand}, ${presets.brandDark} );
  color: ${presets.logo};
  min-height: 25vw;
  padding:0;
  padding-top: ${rhythm(1)};
  padding-bottom: ${rhythm(1)};
  svg.diagonal {
    position: absolute;
    bottom: -1px;
    width: 100%;
    height: 6vw;
    /* set height to pixels if you want angle to change with screen width */
  }
`;

export default (props) => {
  return(
    <Diagonal>
      {props.children}
      <svg className="diagonal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon
          fill="white"
          points="
            0,100
            0,96
            100,0
            100,100"
          // stroke="purple"
        />
      </svg>
    </Diagonal>
  );
}
