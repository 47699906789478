import React from 'react'
import styled from 'styled-components'
import presets from '../utils/presets'
import { rhythm } from '../utils/typography'
import { Link } from 'gatsby'

import P from '../com/P'
import Logo from '../com/BrushLogo'

const color = presets.faded

const Footer = styled.footer`
  color: ${color};
  a{color:${color};}
  font-size: ${rhythm(0.5)};
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  padding: 0 ${rhythm(1)};

  ul {
    list-style: none;
    margin-left: 0;
  }

  li {
    margin-right: ${rhythm(0.5)};
    padding: 0;
  }

  svg {
    width: ${rhythm(2)};
  }
`

export default props => {
  return (
    <Footer>
      <hr />
      <ul>
        <li><Link to="/"><P center><Logo faded/></P></Link></li>
        <li><Link to="/contact/">contact us</Link></li>
        <li><Link to="/terms/">terms &amp; conditions</Link></li>
        <li><Link to="/privacy/">your data, privacy &amp; cookies</Link></li>
        <li>&copy; 2019 Dec Hands Ltd</li>
      </ul>
    </Footer>
  )
}
