// set some values we will re-use here

// const decHandsBlue = '#004494';
// const decHandsYellow = "#fbe100";
const white = '#FFFFFF'
const turquoise = '#25E5C9'
const darkturquoise = '#23CCAF'
// const black = '#000000'
const fadedGrey = '#888888'
const errorRed = '#e52564'
const grey = '#222'

export default {
  //colors
  brand: turquoise,
  brandDark: darkturquoise,
  logo: grey,
  light: white,
  dark: grey,
  faded: fadedGrey,
  error: errorRed,

  //Icon Defaults
  IconWidth: '100%',
  LineWidth: 1,
}
