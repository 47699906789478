import styled from 'styled-components'
import {rhythm} from '../utils/typography'

const H2 = styled.h2`
  margin:0 auto;
  margin-bottom: ${rhythm(2)};
  text-transform: capitalize;
  ${'' /* text-align: ${props => props.center ? 'center' : 'left'}; */}
`;

export default H2
