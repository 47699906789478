import styled from 'styled-components'
import {rhythm} from '../utils/typography'

const Section = styled.section`
  width:100%;
  padding-top: ${rhythm(1)};
  padding-bottom: ${rhythm(1)};
  @supports(display:flex){
    display: flex;
    flex-direction: column;
    ${'' /* align-items: center; */}
    justify-content: center;
    ${'' /* align-self: center; */}
  }
  @media screen and (min-device-width: 0){
      min-height: 80vh;
    }
    @media screen and (min-device-width: 750px){
        min-height: 50vh;
      }
`;

export default Section
