import Typography from 'typography'

const typography = new Typography({
  baseFontSize: "20px",
  baseLineHeight: 1.6,
  scaleRatio: 2, //6.854
  headerWeight:'800',
  bodyWeight:'400',
  boldWeight:'700',
  includeNormalize:true,
  //get google fonts
  googleFonts:[
  {
    name: 'Montserrat',
    styles: [
	  '300',
	  '300i',
	  '400',
	  '400i',
    '700',
    '800',
    '900',
    ],
  },
],
//set families
  headerFontFamily: [
    "Montserrat",
    "sans-serif",
  ],
  bodyFontFamily: [
    "Montserrat",
    "sans-serif",
  ],

})

const { rhythm, scale } = typography;

export { rhythm, scale, typography as default };
