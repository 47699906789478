import styled from 'styled-components'
import {rhythm} from '../utils/typography'

const Container = styled.div`
  width:100%;
  /* width:100vw; */
  padding: 0 ${rhythm(1)};
  text-align: ${props => props.center ? 'center' : 'left'};
  box-sizing: border-box;
  /* background-color:grey; */

  @media screen and (min-width: 750px){
      max-width: ${rhythm(36)};
      margin:0 auto;
    }

`

export default Container