import React from 'react'
import styled from 'styled-components'
import presets from '../utils/presets'
import {rhythm} from '../utils/typography'
import {Link} from 'gatsby'

import Logo from './BrushLogo'
import Container from './Container'

const NavBackground = styled.div`
  background-color: ${presets.brand};
  padding-top: ${rhythm(1)};
`

const NavContactHeader = styled.nav`
  svg{width:${rhythm(1.5)};}
  span,a{
    display:inline-block;
    line-height:1;
    }
`



export default (props) => {
  return(
    <NavBackground>
      <Container>
        <NavContactHeader {...props} >
          <Link to="/" className="logo" alt="home button"><Logo alt="logo"/></Link>
          {/* <Link to="/contact/">contact</Link> */}
          {/* <a href="https://www.facebook.com/Dec-Hands-Ltd-779659805434007/photos" target="_blank" rel="noopener noreferrer">see our work</a> */}
        </NavContactHeader>
      </Container>
    </NavBackground>
  );
}