import styled from 'styled-components'
import {rhythm} from '../utils/typography'

const P = styled.p`
  margin-bottom: ${rhythm(1)};
  ${'' /* text-align: ${props => props.center ? 'center' : 'left'}; */}
  font-size: ${props => props.caption ? '0.75rem' : '1rem'};
  text-transform: ${props => props.caption ? 'uppercase' : 'none'};
`;
export default P
