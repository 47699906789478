import React from 'react'
import Helmet from 'react-helmet'

//Get Components
import Nav from './Nav'

// Get global styles
import '../styles/index.styl'

export default ({ children }) => (
  <div>
    <Helmet>
      {/* <link rel="apple-touch-icon" sizes="57x57" href="/icons/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/icons/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/icons/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/icons/apple-icon-76x76.png" />
      <link rel="apple-touch-icon" sizes="114x114" href="/icons/apple-icon-114x114.png" />
      <link rel="apple-touch-icon" sizes="120x120" href="/icons/apple-icon-120x120.png" />
      <link rel="apple-touch-icon" sizes="144x144" href="/icons/apple-icon-144x144.png" />
      <link rel="apple-touch-icon" sizes="152x152" href="/icons/apple-icon-152x152.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-icon-180x180.png" /> */}
      {/* <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" /> */}
      {/* <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" /> */}
      <link rel="icon" type="image/png" sizes="48x48" href="/icons/icon-48x48.png" />
      <link rel="icon" type="image/png" sizes="72x72" href="/icons/icon-72x72.png" />
      <link rel="icon" type="image/png" sizes="96x96" href="/icons/icon-96x96.png" />
      <link rel="icon" type="image/png" sizes="144x144" href="/icons/icon-144x144.png" />
      <link rel="icon" type="image/png" sizes="192x192"  href="/icons/icon-192x192.png" />
      <link rel="icon" type="image/png" sizes="256x256"  href="/icons/icon-256x256.png" />
      <link rel="icon" type="image/png" sizes="384x384"  href="/icons/icon-384x384.png" />
      <link rel="icon" type="image/png" sizes="512x512"  href="/icons/icon-512x512.png" />
      <link rel="manifest" href="/manifest.json" />
      <meta name="msapplication-TileColor" content="#25E5C9" />
      {/* <meta name="msapplication-TileImage" content="/icons/ms-icon-144x144.png" /> */}
      <meta name="theme-color" content="#25E5C9" />
      {/* <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" /> */}
      <link rel="icon" href="/favicon.ico" type="image/x-icon" />
    </Helmet>
    <Nav />
    {children}
</div>
);
